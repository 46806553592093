////
/// @group nav
////

/// A Bootstrap 4 mixin for generating a `.nav-divider`. Used within dropdowns and nav lists.
/// @deprecated
/// @param {Color} $color[$nav-divider-color]
/// @param {Number} $margin-y[$nav-divider-margin-y]
/// @param {Bool} $ignore-warning[false]

@mixin nav-divider(
	$color: $nav-divider-color,
	$margin-y: $nav-divider-margin-y,
	$ignore-warning: false
) {
	border-top: 1px solid $color;
	height: 0;
	margin: $margin-y 0;
	overflow: hidden;

	@include deprecate(
		'The `nav-divider()` mixin',
		'v4.4.0',
		'v5',
		$ignore-warning
	);
}

/// A mixin to create a `.nav` color variant.
/// @param {Map} $map - A map of `key: value` pairs. The keys and value types are listed below:
/// @example
/// (
/// 	enabled: {Bool}, // Set to false to prevent mixin styles from being output. Default: true
/// 	// .nav
/// 	nav-link: (
/// 		// .nav .nav-link
/// 		// See clay-link for additional selectors
/// 	),
/// 	nav-link-monospaced: (
/// 		// .nav .nav-link-monospaced
/// 		// See clay-link for additional selectors
/// 	),
/// 	nav-btn: (
/// 		// .nav .nav-btn
/// 		// See clay-button-variant for additional selectors
/// 	),
/// 	nav-btn-monospaced: (
/// 		// .nav .nav-btn-monospaced
/// 		// See clay-button-variant for additional selectors
/// 	),
/// 	nav-item: (
/// 		// .nav .nav-item
/// 		before: (
/// 			// .nav .nav-item::before
/// 		),
/// 		after: (
/// 			// .nav .nav-item::after
/// 		),
/// 	),
/// 	nav-divider: (
/// 		// .nav .nav-divider
/// 		before: (
/// 			// .nav .nav-divider::before
/// 		),
/// 		after: (
/// 			// .nav .nav-divider::after
/// 		),
/// 	),
/// 	nav-form: (
/// 		// .nav .nav-form
/// 	),
/// )
/// -=-=-=-=-=- Deprecated -=-=-=-=-=-
/// bg: {Color | String | Null},
/// link-bg: {Color | String | Null},
/// link-border-radius: {Number | String | List | Null},
/// link-color: {Color | String | Null},
/// link-font-weight: {Number | String | Null},
/// link-hover-bg: {Color | String | Null},
/// link-hover-color: {Color | String | Null},
/// link-focus-bg: {Color | String | Null},
/// link-focus-color: {Color | String | Null},
/// link-disabled-bg: {Color | String}, // Default: transparent
/// link-disabled-color: {Color | String}, // Default: $nav-link-disabled-color
/// link-disabled-opacity: {Number | String | Null},
/// link-active-bg: {Color | String | Null},
/// link-active-color: {Color | String | Null},
/// link-active-font-weight: {Number | String | Null},
/// @todo
/// - Add @example
/// - Add @link to documentation

@mixin clay-nav-variant($map) {
	@if (type-of($map) == 'map') {
		$enabled: setter(map-get($map, enabled), true);

		$base: map-merge(
			$map,
			(
				background-color:
					setter(map-get($map, bg), map-get($map, background-color)),
			)
		);

		$nav-link: setter(map-get($map, nav-link), ());

		$nav-link-hover: setter(map-get($nav-link, hover), ());
		$nav-link-hover: map-merge(
			$nav-link-hover,
			(
				background-color:
					setter(
						map-get($map, link-hover-bg),
						map-get($nav-link-hover, background-color)
					),
				color:
					setter(
						map-get($map, link-hover-color),
						map-get($nav-link-hover, color)
					),
			)
		);

		$nav-link-focus: setter(map-get($nav-link, focus), ());
		$nav-link-focus: map-merge(
			$nav-link-focus,
			(
				background-color:
					setter(
						map-get($map, link-focus-bg),
						map-get($nav-link-focus, background-color)
					),
				color:
					setter(
						map-get($map, link-focus-color),
						map-get($nav-link-focus, color)
					),
			)
		);

		$nav-link-active-class: setter(map-get($nav-link, active-class), ());
		$nav-link-active-class: map-merge(
			$nav-link-active-class,
			(
				background-color:
					setter(
						map-get($map, link-active-bg),
						map-get($nav-link-active-class, background-color)
					),
				color:
					setter(
						map-get($map, link-active-color),
						map-get($nav-link-active-class, color)
					),
				font-weight:
					setter(
						map-get($map, link-active-font-weight),
						map-get($nav-link-active-class, font-weight)
					),
			)
		);

		$nav-link-disabled: setter(map-get($nav-link, disabled), ());
		$nav-link-disabled: map-merge(
			$nav-link-disabled,
			(
				background-color:
					setter(
						map-get($map, link-disabled-bg),
						map-get($nav-link-disabled, background-color)
					),
				color:
					setter(
						map-get($map, link-disabled-color),
						map-get($nav-link-disabled, color)
					),
				opacity:
					setter(
						map-get($map, link-disabled-opacity),
						map-get($nav-link-disabled, opacity)
					),
			)
		);

		$nav-link: map-deep-merge(
			$nav-link,
			(
				background-color:
					setter(
						map-get($map, link-bg),
						map-get($nav-link, background-color)
					),
				border-radius:
					setter(
						map-get($map, link-border-radius),
						map-get($nav-link, border-radius)
					),
				color:
					setter(
						map-get($map, link-color),
						map-get($nav-link, color)
					),
				font-weight:
					setter(
						map-get($map, link-font-weight),
						map-get($nav-link, font-weight)
					),
				hover: $nav-link-hover,
				focus: $nav-link-focus,
				active-class: $nav-link-active-class,
				disabled: $nav-link-disabled,
			)
		);

		@if ($enabled) {
			@if (length($base) != 0) {
				@include clay-css($base);
			}

			@if (length($nav-link) != 0) {
				.nav-link {
					@include clay-link($nav-link);
				}
			}

			$_nav-link-monospaced: map-get($map, nav-link-monospaced);

			@if ($_nav-link-monospaced) {
				.nav-link-monospaced {
					@include clay-link($_nav-link-monospaced);
				}
			}

			$_nav-btn: map-get($map, nav-btn);

			@if ($_nav-btn) {
				.nav-btn {
					@include clay-button-variant($_nav-btn);
				}
			}

			$_nav-btn-monospaced: map-get($map, nav-btn-monospaced);

			@if ($_nav-btn-monospaced) {
				.nav-btn-monospaced {
					@include clay-button-variant($_nav-btn-monospaced);
				}
			}

			$_nav-item: map-get($map, nav-item);

			@if ($_nav-item) {
				.nav-item {
					@include clay-css($_nav-item);

					$_before: map-get($_nav-item, before);

					@if ($_before) {
						&::before {
							@include clay-css($_before);
						}
					}

					$_after: map-get($_nav-item, after);

					@if ($_after) {
						&::after {
							@include clay-css($_after);
						}
					}
				}
			}

			$_nav-divider: map-get($map, nav-divider);

			@if ($_nav-divider) {
				.nav-divider {
					@include clay-css($_nav-divider);

					$_before: map-get($_nav-divider, before);

					@if ($_before) {
						&::before {
							@include clay-css($_before);
						}
					}

					$_after: map-get($_nav-divider, after);

					@if ($_after) {
						&::after {
							@include clay-css($_after);
						}
					}
				}
			}

			$_nav-form: map-get($map, nav-form);

			@if ($_nav-form) {
				.nav-form {
					@include clay-css($_nav-form);
				}
			}

			$_dropdown-menu: map-get($map, dropdown-menu);

			@if ($_dropdown-menu) {
				.dropdown-menu {
					@include clay-dropdown-menu-variant($_dropdown-menu);
				}
			}

			$_tab-content: map-get($map, tab-content);

			@if ($_tab-content) {
				+ .tab-content {
					@include clay-css($_tab-content);

					$_tab-pane: map-get($_tab-content, tab-pane);

					@if ($_tab-pane) {
						.tab-pane {
							@include clay-css($_tab-pane);
						}
					}
				}
			}
		}
	}
}
