/**
 * Clay 3.134.0
 *
 * SPDX-FileCopyrightText: © 2020 Liferay, Inc. <https://liferay.com>
 * SPDX-FileCopyrightText: © 2020 Contributors to the project Clay <https://github.com/liferay/clay/graphs/contributors>
 *
 * SPDX-License-Identifier: BSD-3-Clause
 */

/**
 * Bootstrap v4.4.1
 *
 * SPDX-FileCopyrightText: © 2019 Twitter, Inc. <https://twitter.com>
 * SPDX-FileCopyrightText: © 2019 The Bootstrap Authors <https://getbootstrap.com/>
 *
 * SPDX-License-Identifier: LicenseRef-MIT-Bootstrap
 */
