$modal-sm: 320px !default;
$modal-md: 600px !default;
$modal-lg: 896px !default;
$modal-xl: 1140px !default;

$modal-backdrop-bg: $gray-800 !default;
$modal-backdrop-opacity: 0.8 !default;

$modal-content-box-shadow-xs: 0 0 3px 1px rgba(0, 0, 0, 0.2) !default;
$modal-content-border-color: transparent !default;

$modal-inner-padding: 1.5rem !default; // 24px

// .modal-header

$modal-header-border-color: $gray-300 !default;
$modal-header-padding: 1.5rem !default; // 24px

$modal-header: () !default;
$modal-header: map-deep-merge(
	(
		border-color: $modal-header-border-color,
		padding: $modal-header-padding,
	),
	$modal-header
);

$modal-title-font-size: 1.25rem !default; // 20px
$modal-title-font-weight: $font-weight-bold !default;
$modal-title-line-height: $headings-line-height !default;

// Modal Success

$modal-success: () !default;
$modal-success: map-deep-merge(
	(
		modal-header: (
			background-color: $success-l2,
			border-color: $success-l1,
			color: $success,
			close: (
				color: $success,
				hover: (
					color: inherit,
				),
				focus: (
					color: inherit,
				),
				disabled: (
					color: inherit,
				),
			),
		),
	),
	$modal-success
);

// Modal Info

$modal-info: () !default;
$modal-info: map-deep-merge(
	(
		modal-header: (
			background-color: $info-l2,
			border-color: $info-l1,
			color: $info,
			close: (
				color: $info,
				hover: (
					color: inherit,
				),
				focus: (
					color: inherit,
				),
				disabled: (
					color: inherit,
				),
			),
		),
	),
	$modal-info
);

// Modal Warning

$modal-warning: () !default;
$modal-warning: map-deep-merge(
	(
		modal-header: (
			background-color: $warning-l2,
			border-color: $warning-l1,
			color: $warning,
			close: (
				color: $warning,
				hover: (
					color: inherit,
				),
				focus: (
					color: inherit,
				),
				disabled: (
					color: inherit,
				),
			),
		),
	),
	$modal-warning
);

// Modal Danger

$modal-danger: () !default;
$modal-danger: map-deep-merge(
	(
		modal-header: (
			background-color: $danger-l2,
			border-color: $danger-l1,
			color: $danger,
			close: (
				color: $danger,
				hover: (
					color: inherit,
				),
				focus: (
					color: inherit,
				),
				disabled: (
					color: inherit,
				),
			),
		),
	),
	$modal-danger
);
